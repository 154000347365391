// ==========================================================================
// Sub Banner Card Styles
// ==========================================================================
/* Display Adjustments for Banner */

#subbanner {
    min-height: @banner-height-subpage;
    background-size: cover;   
    border: 0.0625rem solid #fff;
    background-color: #fff;
    -moz-box-shadow: 0 0 0.375rem 0.125rem #d9dfe4;
    -webkit-box-shadow: 0 0 0.375rem 0.125rem #d9dfe4;
    box-shadow: 0 0 0.375rem 0.125rem #d9dfe4;   
}

#subbanner .slick-slider {
    margin-bottom: 0px;
}

.sub-page {
    #previewPanel{
        overflow:hidden;
         .slick-initialized .slick-slide {
            min-height: 280px;
            padding: 0px;
        }   
    }
    
    #previewPanel,.banner {
        background: #FFF;
        .ccms-banner, .slider {
            min-height: @banner-height-subpage;
            max-height: @banner-height-subpage;
            overflow: hidden;
        }
        .ccms-banner:after{display: none;}
        
        .banner-slide {min-height: @banner-height-subpage;}
        
        .ccms-banner-overlay, .slick-next::before, .slick-prev::before, .slick-prev, .slick-next, .slick-dots {
            display: none !important;
        }
        
    }
}