// ==========================================================================
// Google Calendar Card Styles
// ==========================================================================
.latest-color-box{
    #calendarPanel, .ccmsGoogleCalendar{
        a {color:contrast(@color-secondary,#fff, #111);}
    }
} 

#calendarPanel, .ccmsGoogleCalendar{
    a { color: #444;}
    .fc-unthemed td { border-color: #BBB;}
 
    .fc-day-header {background: #ddd; color: #333; border-color: #ddd;}
    .fc-day { background: #f9f9f9; border-color: #BBB;}
    .fc-other-month {background: #f9f9f9; color: #DDD;}
    
    .fc-day-number {
        background: #f5f5f5 !important;
    }
    
    .fc-day-number.fc-today {
        color: #FFF;
        background: #666 !important;
    }
    
    .fc-event {
        color: @color-primary !important;
        border-radius: 0;
        background-color: #FFF !important;
        border: none;
        border-bottom: 1px solid #f5f5f5;
        border-color: #f5f5f5 !important;
        .transition;
        
        &:hover {
            color: @color-text !important;
        }
        
        .fc-content {text-overflow: ellipsis;}
    }
    
    
    .fc-corner-right, .fc-corner-left {border-radius: 0 !important;}
    
    .fc-state-default {
        border-color: rgba(0, 0, 0, 0.1) !important;
        background-color: #f5f5f5;
        background-image: none;
        color: @color-text;
        outline: none !important;
        text-shadow: none !important;
        .transition;
    }
    
    .fc-state-active {
        background-color: @color-primary !important;
        color: #FFF;
        box-shadow: none !important;
    }
    
    .fc-state-hover {
        background-color: #e9e9e9 !important;
    }
    
    .fc-state-down {
        box-shadow: none !important;
    }
    
    .fc-day-number {
        color: #999;
        font-weight: bold;
        font-size: 70%;
    }
    
    
    
    /* Event Feed */
    ul.ccms-gcalendar-calendar-feed-list { 
        li { border: none; margin-bottom: 0;
            article { font-size: 90%;
                div {font-size: 100%; padding-bottom: 0;}
                h4 { color: @color-primary; font-size: 105%;}
            }
            h3 { width: 25%; background: #3e4650; color: #FFF; padding-top: 0; .transition;
                span { font-weight: 300;}
                span.ccms-gcalendar-day {display: none;}
                span.ccms-gcalendar-date { font-size: 180%; font-weight: bold; padding: 3px;}
                span.ccms-gcalendar-month { 
                    font-size: 95%;
                    padding: 3px;
                    background-color: @color-primary;
                    color: contrast(@color-primary, #fff, #111);
                }
            }
            
            &.ccms-gcalendar-full {
                display: inline-block;
                position: relative;
                font-size: 12px;
                font-size: 0.75rem;
                color: inherit;
                padding-top: 0.375rem;
                font-weight: normal;
                .transition;
                &:before {
                    display: block;
                    content: " ";
                    height: 1px;
                    width: 30%;
                    border-top: 1px solid #016db9;
                    position: absolute;
                    top: 0;
                }
                &:after {
                    font-family: FontAwesome;
                    content: "\f178";
                    color: @color-primary;
                    display: inline-block;
                    margin: 0 0 0 .5rem;
                    position: relative;
                    top: 1px;
                }

                &:hover {color: @color-primary;}
            }
            
            &:hover {background: transparent; 
                h3 { background: @color-primary; }
            }
        } 
    }    
}

.ccms-gcalendar-description {
    height: 4.1em;
    overflow: hidden;
}