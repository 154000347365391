// ==========================================================================
// Tabbed Content Card Styles
// ==========================================================================
.responsive-tabs__heading{ 
    background-color: #efefef !important;
    font-size: 100%;
    margin-bottom: 10px !important;
    padding: 5px 0 !important;
    text-align: center;
    line-height: normal !important;
    border-radius: 0;
    box-shadow: none;
}
.ccms-tabbedcontent-tab {
    &:extend(.responsive-tabs__heading);
    line-height: 2 !important;
    margin: 0 !important;
    padding: 0 15px !important;
    font-family: @font-primary !important;
    font-size: @font-size * 0.8125 !important;
    border-width: 1px !important;
    border-radius: 0 !important;
    font-weight: bold;
    border-color: #CCC;
    border-bottom: none;
    .transition;
}
    .ccms-tabbedcontent-tab.ccms-tabbedcontent-active, .responsive-tabs__heading--active {
    &:hover, & {
        background: @color-primary !important;
        color: contrast(@color-primary, #fff, #111, 40%) !important;
        border-color: @color-primary;
    } 
}
.responsive-tabs__list__item:hover, .responsive-tabs__heading:hover {
    background: @color-secondary !important;
    color: contrast(@color-secondary, #fff, #111) !important;
}
.responsive-tabs__heading:hover::after {
    border-top: 10px solid #ffffff !important;
}
.responsive-tabs__list {
    border-bottom: 5px solid @color-primary !important;
    margin: 0;
}
.responsive-tabs__panel {
    padding: 20px !important;
    border: 1px solid #ccc;
}