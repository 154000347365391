// ==========================================================================
// Slide Show Card Styles
// ==========================================================================
.ccmsImageRotator {
    margin-bottom: 0;
    .slick-slider {
        margin-bottom: 0;
        .slick-slide {
            border-radius: 0 !important;
            &::after {
                border-radius: 0;
                bottom: 0;
                content: "";
                left: 0;
                top:0;
                position: absolute;
                right: 0;
                width: 100%;
                z-index: 0;
                box-shadow: inset 0 0 50px 0px rgba(0, 0, 0, 0.5);
            }
            .ccms-ImageRotator-overlay {
                background: fade(@color-secondary, 85%);
                border-radius: 0;
                min-height: 15%;
                height: auto;
                p {
                    position: relative;
                    text-align: center;
                    z-index: 1;
                }
            }
        }
        .slick-prev, .slick-next {
            background: rgba(0,0,0,0) !important;
            margin-top: 24%;
            z-index: 2;
            &:hover {
                background: rgba(0,0,0,0) !important;
            }
        }
        .slick-prev::before {
            content: "\f177";
        }
        .slick-next::before {
            content: "\f178";
        }
        .slick-dots {
            display: none !important;
        }
    }
}