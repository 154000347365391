// ==========================================================================
// Banner Card Styles
// ==========================================================================

/* ============================= Slider ============================= */ 

/* Banner General */

    .ccms-banner {
        position: relative;
        overflow: hidden;
        .background-cover;
        background-position: center right !important;
        padding: 10px !important;
        .visuallyhidden{color:#fff;}
    }

/* Banner */

#previewPanel,
.banner {
    background: #FFF;
    
    .ccms-banner, .slider {
        min-height: @banner-height;
    }
    
    .banner-slide {min-height: @banner-height;}
    
    .ccms-banner-slider {        
        background-color: @color-body;
        
        .slick-next, .slick-prev {
            display: none!important;
        }
    }

    .ccms-banner-slider .slick-dots {
        position: absolute;
        max-width: 80%;
        width: auto;
        left: 17%;
        bottom: 50px;
        padding: 0;
        li {
            list-style: none;
            display: inline-block;
            margin: 0 25px 0 0;
            button {
                font-size: 0;
                padding: 0;
                margin: 0;
                background: transparent;
                color: #FFF;
                height: 14px !important;
                &:after {
                    content: '\f111';
                    font-size: 14px;
                    font-family: @font-icon;
                }
                &:focus {outline: none;}
            }
            
            &:last-child { margin: 0;}
        }
    }
    
    .slick-active button { color: @color-primary !important;}
    
    .ccms-banner-overlay {
        position: absolute;
        left: 13%;
        bottom: auto;
        top: 13%;
        background-color: fade(@color-secondary, 70%);
        padding: 1rem;
        color: #fff;
        box-shadow: none;
        max-width: 80%;
        width: auto;
        text-align: left;
        
        h1 {
            color: inherit;
            font-size: 40px;
            font-size: 2.5rem;
            text-transform: uppercase;
            margin: 0;
            font-weight:700;
        }
             
        p {
            border: none;
            color: inherit;
            font-size: 14px;
            font-size: 0.875rem;
            padding: 0;
            margin: 0 0 0.5rem;
            border-top: none;
            border-bottom: none;
            overflow: initial;
            white-space: normal;
        }
    }
}

.slick-slider { margin-bottom: 0; }

.ccms-banner:after { background-color: transparent; }

a.ccms-banner-button, a.ccms-banner-button:link, a.ccms-banner-button:visited {
    box-shadow: none;
    background: @color-primary;
    font-size: 12px;
    font-size: 0.75rem;
    letter-spacing: 1px;
    padding: 12px 20px;
    border-radius: 4px;
    outline: none;
    .transition;
    margin-top: 12px;
}

a.ccms-banner-button:hover {
    background: @color-secondary;
}

.ccms-banner-slider .slick-dots li button:before {
    content: "";
}


/* Catapult CMS Display Adjustments (Overview) */
.rspPane .slick-slider {
    margin-bottom: 0px;
    overflow: hidden;
}

.rspPane .banner button.slick-next, .rspPane .banner button.slick-prev {
    display: none!important;
}

.rspPane .banner {
    .ccms-banner, .slider {
        min-height: @banner-height;
    }
    .banner-slide {min-height: @banner-height;}
}


/*Preview Pane Adjustments (Editor)*/

#previewPanel {
    .ccms-banner-slider {
        .slick-prev, .slick-next {display: none !important;}
    }
}