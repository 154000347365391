/****************************
*   ANNOUNCMENT OVERRIDES
*****************************/

#catapultannounce-content {
    li.catapultannounce-item.catapultannounce-item-priority,
    li.catapultannounce-item.catapultannounce-item-priority:nth-child(2n),
    li.catapultannounce-item:nth-child(2n) {background-color: transparent;}
    date.catapultannounce-date {visibility: hidden;}
    
    li.catapultannounce-item { border-bottom: none; padding: 10px 0;
        &:hover {
            background-color: transparent !important;
            span.readmore { 
                background: none;
                color: @color-secondary;
            }
        }
    }
    
    .catapultannounce-title {
        font-family: @font-tertiary;
    }
    
    a.all-catapultannounce-link {
        color: inherit;
        border: 1px solid @color-secondary;
        font-weight: 400;
        text-decoration: none;
        font-size: 85%;
        font-style: italic;
        
        &:hover {
            background-color: @color-secondary;
            color: #000;
        }
    }
    
    .catapultannounce-image-holder .catapultannounce-image {
        outline: none;
        border: 3px solid @color-secondary;
        padding-bottom: 100%;
        background-repeat: no-repeat;
    }
    
    .catapultannounce-description {font-size: 90%;}
    
    .catapultannounce-feed-view .catapultannounce-list li.catapultannounce-item:first-of-type 
    {
        border-top: none;
    }
    
    /* Trying to force span.readme onto its own line breaks the announcement loader.
     * My guess is that doing so screws with dotdotdot (js) which in turn removes most,
     * if not all, of the description text from the DOM.
    */
    
    .catapultannounce-item span.readmore {
        color: inherit;
        border: none;
        margin-left: 5px;
        margin-top: 0;
        padding: 0;
        font-size: inherit;
        font-weight: 400;
        text-decoration: underline;
        font-style: italic;
    }
}

.catapultannounce-popup date.catapultannounce-date {
    color: #111;
}

#catapultannounce-container date.catapultannounce-date {
    color: #111;
}